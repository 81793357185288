<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    entity-type="carver"
    locale-section="pages.carvers"
    field-section="carvers"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "CarverDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: carver
        carver
      `,
      inputAttributes: {
        carver: { required: true }
      }
    };
  }
};
</script>
