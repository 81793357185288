export default {
  data() {
    return {
      leaveGuard: {
        to: undefined,
        forceLeave: false,
        modalActive: false,
        hasChanged: false
      }
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.canLeave) {
      this.leaveGuard.to = to;
      this.leaveGuard.modalActive = true;
      next(false);
    } else {
      next();
    }
  },
  computed: {
    canLeave() {
      return (
        this.leaveGuard.modalActive ||
        this.leaveGuard.hasChanged === false ||
        this.leaveGuard.forceLeave === true
      );
    }
  }
};
